import "styles/globals.scss";
import { MantineProvider } from "@mantine/core";
import Script from "next/script";
import type { AppProps as NextAppProps } from "next/app";

import { useRouter } from "next/router";
import { useCallback, useEffect } from "react";
import { IntlProvider } from "react-intl";
import Head from "next/head";
import { SSRCookies, SSRKeycloakProvider } from "@react-keycloak-fork/ssr";
import { KeycloakConfig } from "keycloak-js";
import { Layout } from "@/components/layout/Layout";
import { IntlMessages } from "@/utils/loadIntlMessages";
import { loadDayJSLocale, LoadDayJSLocaleProps } from "@/utils/loadDayJSLocale";
import { mantineTheme } from "@/styles/theme";
import ErrorBoundary from "@/components/common/errorBoundary/ErrorBoundary";
import { Error } from "@/components/pages/error/Error";
import TrackingService from "@/utils/trackingService";
import { ReviewsConst } from "@/components/common/reviewsBlock/ReviewsBlock";
import AuthGuard from "@/components/common/authGuard/AuthGuard";

export type PageProps = {
  layout: Layout;
  requireAuth?: boolean;
  intlMessages?: IntlMessages;
  reviewsConst?: ReviewsConst;
};

type AppProps<P> = {
  pageProps: P;
} & Omit<NextAppProps<P>, "pageProps">;

interface InitialProps {
  cookies: unknown;
}

const App = ({
  Component,
  pageProps,
  cookies,
}: AppProps<PageProps> & InitialProps) => {
  const { locale = "", defaultLocale, events } = useRouter();
  void loadDayJSLocale({ locale } as LoadDayJSLocaleProps);

  const keycloakConfig: KeycloakConfig = {
    url: process.env.NEXT_PUBLIC_KEYCLOAK_URL,
    realm: process.env.NEXT_PUBLIC_KEYCLOAK_REALM as string,
    clientId: process.env.NEXT_PUBLIC_KEYCLOAK_CLIENT_ID as string,
  };

  // Subscribe to NextRouter events to listen to page transitions
  // This is useful to tell analytics tools that the current URL has changed
  useEffect(() => {
    const handleRouteChange = () => {
      TrackingService.trackPageView();
    };

    events.on("routeChangeComplete", handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      events.off("routeChangeComplete", handleRouteChange);
    };
  }, [events]);

  const onKeycloakEvent = useCallback((event: string) => {
    if (event === "onAuthSuccess") {
      TrackingService.identifyUser();
    } else if (event === "onAuthLogout") {
      TrackingService.logoutUser();
    }
  }, []);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, minimum-scale=1.0, maximum-scale = 1.0, user-scalable = no"
        ></meta>
        <meta
          name="facebook-domain-verification"
          content="m320vv75o7deirm048upjhmcmf5fzu"
        />
      </Head>
      {/* Load the Axeptio SDK as soon as possible. No navigation on the website is allowed
      prior to the user accepting/declining cookies */}
      <Script
        id="axeptio-sdk"
        src="https://static.axept.io/sdk-slim.js"
        type="text/javascript"
        async
        data-id={process.env.NEXT_PUBLIC_AXEPTIO_ID ?? ""}
        data-cookies-domain={
          process.env.NEXT_PUBLIC_COOKIE_DOMAIN ??
          process.env.NEXT_PUBLIC_DOMAIN ??
          "localhost"
        }
        data-cookies-version="rentacar_prisme-base"
        strategy="beforeInteractive"
      />
      {/* The Rudder SDK can be loaded after page has become interactive. 
    If there were any calls to it, they would have been stored into a global object
    and will be replayed by the SDK when it has finished loading*/}
      <Script
        id="rudder-js-sdk"
        src="https://cdn.rudderlabs.com/v1.1/rudder-analytics.min.js"
        type="text/javascript"
        strategy="afterInteractive"
      />

      {/* When the used has given their cookie preferences, tell the Rudder SDK which
    trackers it can load, and manually load those that it does no support */}
      <Script
        id="axeptio-settings"
        strategy="afterInteractive"
        // no need to use injectPurifiedHTML here
        dangerouslySetInnerHTML={{
          __html: `
        // Define dataLayer and the gtag function.
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}

        gtag('consent', 'default', {
          'ad_storage': 'denied',
          'ad_user_data': 'denied',
          'ad_personalization': 'denied',
          'analytics_storage': 'denied'
        });
        
        window.bing4048888 = window.bing4048888 || [];window.bing4048888.push('consent', 'default', {'ad_storage': 'denied'});
        

        function loadAwin() {
          var el = document.createElement('script');
          el.setAttribute('type', 'text/javascript');
          el.setAttribute('defer', true);
          el.setAttribute('id', 'awin-script-tag');
          el.setAttribute('src', 'https://www.dwin1.com/${
            process.env.NEXT_PUBLIC_AWIN_ID ?? ""
          }.js');
          document.body.append(el);
        }

        function loadInsider(cookieLess) {
          window.insider_object = window.insider_object || {};
          var script = document.createElement('script');
          window.insiderFlow = cookieLess !== true ? 3 : 1;
          var insiderParams = cookieLess !== true ? 'id=${
            process.env.NEXT_PUBLIC_INSIDER_ID ?? ""
          }' : 'c=0';
          script.id = 'insider-tag-script';
          script.src = 'https://rentacar.api.useinsider.com/ins.js?'+insiderParams;
          script.async = true;
          const previousScript = document.getElementById('insider-tag-script');
          if (previousScript) {
            document.head.removeChild(previousScript);
          }
          document.head.appendChild(script);
        }

        var clicks = [];
        void 0 === window._axcb && (window._axcb = []);
        window._axcb.push(function(axeptio) {
          var wall;

          axeptio.on("ready", function() {
            if (window.axeptioSDK && window.axeptioSDK.userPreferencesManager.userHasAlreadySetChoices === true) {
              return;
            }

            function onBtnClick(event) {
              if (event.target.nodeName !== "BUTTON") return;
              clicks.push({
                label: event.target.textContent,
                originalTimestamp: new Date().toISOString(),
              });
            }

            document.querySelectorAll('#axeptio_overlay').forEach((el) => {
              el.addEventListener('click', onBtnClick);
            });
            
            wall = document.createElement("div");
            //Paramètre permettant de changer la couleur du cookiewall
            wall.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
            wall.style.height = "100%";
            wall.style.width = "100%";
            wall.style.position = "fixed";
            wall.style.top = "0";
            wall.style.left = "0";
            wall.style.zIndex = "20930930";
            wall.style.zIndex = (
              window.getComputedStyle(document.getElementById("axeptio_overlay"))
                .zIndex - 1
            ).toString();
            document.body.appendChild(wall);
          });

          axeptio.on("consent:saved", function(consent){
            if (
              (document.getElementById('awin-script-tag') && !consent.consent.preferences.vendors.awin)
            || (document.getElementById('insider-tag-script') && window.insiderFlow !== 1 && !consent.consent.preferences.vendors.insider)
              ) {
              window.location.reload();
            }
          });

          axeptio.on("cookies:complete", function(choices) {

            try {
              document.body.removeChild(wall);
            } catch (e) {
              // Wall was already removed, pass
            }

            var integrations = {
              All: false,
              GA4: true,
            };
            if (choices.google_analytics) {
              gtag('consent', 'update', {
                'analytics_storage': 'granted'
              });
              // integrations.GA4 = true;
              
            }
            if (choices.Google_ads) {
              gtag('consent', 'update', {
                'ad_storage': 'granted',
                'ad_user_data': 'granted',
                'ad_personalization': 'granted',
              });
              // integrations.GA4 = true;
            }
            if (choices.Bing) {
              window.bing4048888 = window.bing4048888 || [];window.bing4048888.push('consent', 'update', {'ad_storage': 'granted'});
              integrations.BingAds = true;
            }
            if (choices.facebook_pixel) integrations.FACEBOOK_PIXEL = true;
            if (choices.hotjar) integrations.Hotjar = true;
            if (choices.awin) loadAwin();
            if (choices.insider) {
              loadInsider(); // With cookies
            } else {
              loadInsider(true); // Cookieless
            }
            if (choices.criteo) integrations.Criteo = true;
            if (choices.tiktok) integrations.TiktokAds = true;
            if (choices.pinterest) integrations.PinterestTag = true;

            rudderanalytics.load('${
              process.env.NEXT_PUBLIC_RUDDER_WRITE_KEY ?? ""
            }', '${process.env.NEXT_PUBLIC_RUDDER_DATAPLANE_URL ?? ""}', {
              integrations: integrations,
              // storage: {
              //   migrate: true
              // },
              // consentManagement: {
              //   enabled: true,
              //   provider: 'custom',
              //   allowedConsentIds: [
              //     '2Ph6thxP98tX1NvbGBbaLCwwEW9'
              //   ]
              // },
              // preConsent: {
              //   enabled: true,
              //   events: {
              //     delivery: 'immediate'
              //   },
              // },
            });
            
            var racWebVersion = 'cube';
            rudderanalytics.page(
              {
                racWebVersion: racWebVersion,
              },
              {
                context: {
                  traits: {
                    racWebVersion: racWebVersion,
                  },
                }
              }
            );

            while (typeof (c = clicks.shift()) !== "undefined") {
              rudderanalytics.track('Choix', {category: 'Axeptio', label: c.label}, {originalTimestamp: c.originalTimestamp});
            }
          })
        });
        `,
        }}
      />
      <IntlProvider
        locale={locale}
        defaultLocale={defaultLocale}
        messages={pageProps.intlMessages}
      >
        <MantineProvider theme={mantineTheme}>
          <SSRKeycloakProvider
            keycloakConfig={keycloakConfig}
            persistor={SSRCookies(cookies)}
            initOptions={{
              onLoad: "check-sso",
              silentCheckSsoRedirectUri: `${
                process.env.NEXT_PUBLIC_HOST_URL ?? ""
              }/silent-check-sso.html`,
              silentCheckSsoFallback: false,
              checkLoginIframe: false, // localhost Login testing
            }}
            onEvent={onKeycloakEvent}
          >
            <AuthGuard requireAuth={pageProps.requireAuth}>
              <ErrorBoundary FallBackComponent={Error}>
                <Component {...pageProps} />
              </ErrorBoundary>
            </AuthGuard>
          </SSRKeycloakProvider>
        </MantineProvider>
      </IntlProvider>
    </>
  );
};

export default App;
